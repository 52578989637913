import { useObservable } from "rxjs-hooks";
import { collectionData } from "rxfire/firestore";
import { useContext } from "react";
import { map, withLatestFrom } from "rxjs/operators";
import { subMilliseconds, isAfter } from "date-fns";
import {
  WithId,
  IParticipant,
  FirestoreContext,
  PARTICIPANT_PRESENCE_MS,
} from "../firebase";
import { timer } from "rxjs";

export function useParticipantsData(partyId: string) {
  const firestore = useContext(FirestoreContext);

  return useObservable(() =>
    collectionData<WithId<IParticipant>>(
      firestore.doc(`parties/${partyId}`).collection("participants"),
      "id"
    )
  );
}

const filterRecentlySeen = (participants: WithId<IParticipant>[]) => {
  return participants.filter((p) => {
    return isAfter(
      p.lastSeenAt.toDate(),
      subMilliseconds(new Date(), PARTICIPANT_PRESENCE_MS)
    );
  });
};

const takeLatestFromDataSource = (v: [number, WithId<IParticipant>[]]) => v[1];

export function usePresentParticipantsData(partyId: string) {
  const firestore = useContext(FirestoreContext);

  return useObservable(() => {
    const dataSource = collectionData<WithId<IParticipant>>(
      firestore.doc(`parties/${partyId}`).collection("participants"),
      "id"
    );
    const replayer = timer(0, 1000).pipe(
      withLatestFrom(dataSource),
      map(takeLatestFromDataSource)
    );

    return replayer.pipe(map(filterRecentlySeen));
  });
}
