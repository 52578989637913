import React, { useState } from "react";
import { Circle } from "rc-progress";
import { Box } from "../ui/layouts";
import { H1 } from "../ui/atoms";
import useInterval from "../hooks/useInterval";

interface IProps {
  duration: number;
  onComplete: () => void;
}

function Countdown({ duration, onComplete }: IProps) {
  let [count, setCount] = useState(duration);

  useInterval(() => {
    if (count === 0) {
      onComplete();
    } else {
      setCount(count - 1);
    }
  }, 1000);

  return (
    <Box>
      <Box
        width="300px"
        sx={{
          position: "relative",
        }}
      >
        <Circle
          percent={(count / duration) * 100}
          strokeColor="#474747"
          strokeWidth={7}
          strokeLinecap="square"
          trailWidth={7}
          trailColor="white"
        />
        <H1
          fontSize={7}
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          {count}
        </H1>
      </Box>
    </Box>
  );
}

export default Countdown;
