import { useObservable } from "rxjs-hooks";
import { collectionData, collection } from "rxfire/firestore";
import { WithId, IGame, FirestoreContext } from "../firebase";
import { useContext } from "react";
import { map } from "rxjs/operators";

export function currentGameQuery(
  firestore: firebase.firestore.Firestore,
  partyId: string
) {
  return firestore
    .doc(`parties/${partyId}`)
    .collection("games")
    .orderBy("createdAt", "desc")
    .limit(1);
}

export function useCurrentGame(partyId: string) {
  const firestore = useContext(FirestoreContext);

  return useObservable(() =>
    collection(currentGameQuery(firestore, partyId)).pipe(
      map((games) => games[0])
    )
  );
}

export function useCurrentGameData(partyId: string) {
  const firestore = useContext(FirestoreContext);

  return useObservable(() =>
    collectionData<WithId<IGame>>(
      currentGameQuery(firestore, partyId),
      "id"
    ).pipe(map((games) => games[0]))
  );
}
