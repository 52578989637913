import React from "react";
import { Image, ImageProps } from "rebass";

const Avatar: React.FC<ImageProps> = (props: ImageProps) => (
  <Image
    sx={{
      width: props.size || 100,
      height: props.size || 100,
      borderRadius: "50%"
    }}
    {...props}
  />
);

export default Avatar;
