import React, { useContext } from "react";
import { Box, Flex } from "../ui/layouts";
import { H1, H2, Text, Avatar, ImageButton } from "../ui/atoms";
import { useParams } from "react-router-dom";
import { usePresentParticipantsData } from "../hooks/participants";
import crazy8s from "../assets/crazy8s.png";
import productBox from "../assets/ProductBox.png";
import { useObservable } from "rxjs-hooks";
import { docData } from "rxfire/firestore";
import { FirestoreContext, IParty } from "../firebase";

function PartySetup() {
  const firestore = useContext(FirestoreContext);

  let { partyId } = useParams<{ partyId: string }>();
  const party = useObservable(() =>
    docData<IParty>(firestore.doc(`parties/${partyId}`))
  );
  const participants = usePresentParticipantsData(partyId);

  const joinLink = window.location.host;

  if (!party) return <div>Loading...</div>;

  return (
    <Box p={4} bg={"bg"} height="100%" color="textLight">
      <H2 fontSize="30px">Visit on your phone</H2>
      <H1 fontSize="60px" mb={3}>
        {joinLink}
      </H1>
      <H2 fontSize="30px">Enter code</H2>
      <H1 fontSize="60px" mb={4}>
        {party.shortCode}
      </H1>
      <H1 mb={3}>Partiers</H1>
      <Flex flexWrap="wrap" mb={4}>
        {participants && participants.length ? (
          participants.map((participant) => (
            <Flex
              flexDirection="column"
              alignItems="center"
              mr={3}
              mb={3}
              key={participant.id}
            >
              <Box mb={2}>
                <Avatar src={participant.avatar} />
              </Box>
              <Text fontWeight="bold">{participant.name}</Text>
            </Flex>
          ))
        ) : (
          <Text>You need at least two players</Text>
        )}
      </Flex>

      {participants && participants.length > 1 && (
        <>
          <H1 mb={3}>Pick a game to play</H1>

          <Flex>
            <Box mr={3}>
              <ImageButton
                to={`/${partyId}/crazy-eights/setup`}
                image={crazy8s}
              >
                <Text
                  bg="#FFFB92"
                  color="black"
                  fontFamily="Righteous"
                  fontSize={4}
                  width="fit-content"
                >
                  Crazy Eights
                </Text>
              </ImageButton>
            </Box>
            <Box mr={3}>
              <ImageButton
                to={`/${partyId}/crazy-eights/setup`}
                image={productBox}
              >
                <Text
                  color="white"
                  fontFamily="Helvetica Neue"
                  fontSize={4}
                  fontWeight="bold"
                  width="fit-content"
                >
                  Product Box
                </Text>
              </ImageButton>
            </Box>
          </Flex>
        </>
      )}
    </Box>
  );
}

export default PartySetup;
