export default {
  breakpoints: ["40em", "52em", "64em"],
  fontSizes: [12, 14, 16, 20, 24, 32, 48, 64],
  colors: {
    blue: "#07c",
    lightgray: "#f6f6ff",
    textLight: "white",
    bg: "#fefb92",
    border: "#474747",
    activeBorder: "blue"
  },
  space: [0, 4, 8, 16, 32, 64, 128, 256],
  fonts: {
    body: "system-ui, sans-serif",
    heading: "Righteous",
    monospace: "Menlo, monospace"
  },
  fontWeights: {
    body: 400,
    heading: 700,
    bold: 700
  },
  lineHeights: {
    body: 1.5,
    heading: 1.25
  },
  shadows: {
    small: "0 0 4px rgba(0, 0, 0, .125)",
    large: "0 0 24px rgba(0, 0, 0, .125)"
  },
  variants: {},
  text: {
    heading: {
      color: "#474747"
    }
  },

  forms: {
    input: {
      borderColor: "black",
      padding: 16
    }
  },
  buttons: {
    primary: {
      fontSize: 24,
      borderRadius: 0,
      borderWidth: "3px",
      borderColor: "#474747",
      borderStyle: "solid",
      fontFamily: "Righteous",
      color: "#474747",
      bg: "bg"
    }
  }
};
