import React, { useEffect, useState } from "react";
import { useParams, Redirect } from "react-router-dom";
import { Flex, Box, Modal } from "../ui/layouts";
import { H1, Avatar, Text } from "../ui/atoms";
import background from "../assets/fullbackground-crazy8s.png";

import { IGame, GameState } from "../firebase";
import Countdown from "../components/Countdown";
import { useCurrentGame } from "../hooks/currentGame";
import { usePresentParticipantsData } from "../hooks/participants";

import CrazyEightsLoader from "./CrazyEightsLoader";

enum Status {
  Initial,
  TimerActive,
  TimerUp,
  GameStateAdvanced,
}

function CrazyEightsHostGame() {
  let { partyId } = useParams<{ partyId: string }>();
  const participants = usePresentParticipantsData(partyId);
  const game = useCurrentGame(partyId);

  const [status, setStatus] = useState<Status>(Status.Initial);

  const timeUp = async () => {
    setStatus(Status.TimerUp);

    if (game) {
      const firstPresenterId =
        participants && participants[0] ? participants[0].id : "";

      const submissionsQuery = await game.ref
        .collection("submissions")
        .where("participantId", "==", firstPresenterId)
        .orderBy("createdAt", "asc")
        .get();

      const submissions = submissionsQuery.docs;

      await game.ref.set(
        {
          state: GameState.Presentation,
          currentPresenter: {
            id: firstPresenterId,
            submissionId:
              submissions && submissions[0] ? submissions[0].id : "",
          },
        } as Partial<IGame>,
        {
          merge: true,
        }
      );
    }
  };

  useEffect(() => {
    if (game) setStatus(Status.TimerActive);
  }, [game]);

  if (!game) return <CrazyEightsLoader />;

  const gameData = game.data() as IGame;

  if (gameData.state === GameState.Presentation)
    return <Redirect to={`/${partyId}/crazy-eights/host/presentation`} />;

  return (
    <Flex
      p={5}
      height="100%"
      sx={{
        backgroundImage: `url(${background})`,
      }}
    >
      <Modal large>
        <Flex flexDirection="column" height="100%">
          <Flex justifyContent="center">
            {participants &&
              participants.map((participant) => (
                <Flex mx={2} alignItems="center" flexDirection="column">
                  <Avatar
                    size={60}
                    mb={2}
                    key={participant.id}
                    src={participant.avatar}
                    sx={{
                      borderWidth: 3,
                      borderRadius: "50%",
                      borderStyle: "solid",
                      borderColor: "border",
                    }}
                  />
                  <Text fontWeight="bold">{participant.name}</Text>
                </Flex>
              ))}
          </Flex>
          <Flex
            justifyContent="center"
            flexDirection="column"
            alignItems="center"
            flex={1}
          >
            <H1 mb={5}>{gameData.prompt}</H1>

            {status === Status.TimerActive && (
              <Box mb={4}>
                <Countdown
                  duration={gameData.timerSeconds}
                  onComplete={timeUp}
                />
              </Box>
            )}
            <Text mt={4}>Generate as many ideas as you can!</Text>
          </Flex>
        </Flex>
      </Modal>
    </Flex>
  );
}

export default CrazyEightsHostGame;
