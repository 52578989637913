import React, { useContext } from "react";
import { useParams, Redirect } from "react-router-dom";
import { Flex } from "../ui/layouts";
import { H1, Avatar, Text } from "../ui/atoms";
import { GameState } from "../firebase";
import { useCurrentGameData } from "../hooks/currentGame";
import { useParticipantsData } from "../hooks/participants";
import CurrentParticipantContext from "../contexts/CurrentParticipantContext";

function PartyWaitingRoom() {
  let { partyId } = useParams<{ partyId: string }>();

  const { id } = useContext(CurrentParticipantContext);
  const game = useCurrentGameData(partyId);
  const participants = useParticipantsData(partyId);

  // TODO: this better belongs in a custom route
  if (id === null) return <Redirect to="/" />;

  const currentParticipant = participants
    ? participants.find((p) => p.id === id)
    : null;

  if (game) {
    if (game.state === GameState.Draw)
      return <Redirect to={`/${partyId}/crazy-eights`} />;

    if (game.state === GameState.Presentation)
      return <Redirect to={`/${partyId}/crazy-eights/presentation`} />;
  }

  if (!currentParticipant) {
    return <Flex height="100%" bg="bg" />;
  }

  return (
    <Flex
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      height="100%"
      bg="bg"
    >
      <H1 mb={3}>Welcome</H1>
      <Avatar mb={3} src={currentParticipant.avatar} />
      <Text variant="big" fontWeight="bold">
        {currentParticipant.name}
      </Text>
    </Flex>
  );
}

export default PartyWaitingRoom;
