import React, { useContext, useState } from "react";
import styled from "@emotion/styled";
import { Box, Flex } from "rebass";
import { FirestoreContext, IParty, Timestamp } from "../firebase";
import { Redirect } from "react-router-dom";
import pool from "../assets/pool.png";
import tile from "../assets/background-tile.jpeg";
import Header from "../ui/organisms/Header";
import { randomAlpha } from "../util";
import CurrentParticipantContext from "../contexts/CurrentParticipantContext";
import { TextInput, Text, PrimaryButton } from "../ui/atoms";

const Button = styled.button`
  margin-top: 64px;
  background: #ff618d;
  padding: 24px 32px;
  color: white;
  cursor: pointer;
  border: 3px solid white;
  font-family: rubik;
  border-radius: 45px;
  font-weight: 600;
  font-size: 24px;
`;

const Container = styled.div`
  background: #e22d60;
  background-image: url(${tile});
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  background-position-x: -32px;
  align-items: center;
  flex-direction: column;
`;

const PoolImage = styled.img`
  width: 100%;
  max-width: 700px;
`;

function Home() {
  const firestore = useContext(FirestoreContext);
  const currentParticipant = useContext(CurrentParticipantContext);

  const [shortCode, setShortCode] = useState("");
  const [name, setName] = useState("");
  const [joiningParty, setJoiningParty] = useState(false);
  const [joiningPartyId, setJoiningPartyId] = useState<undefined | string>();

  const [creatingParty, setCreatingParty] = useState(false);
  const [newPartyId, setNewPartyId] = useState<undefined | string>();

  const joinParty = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (name === "") {
      alert("Enter your name before joining");
      return;
    }

    setJoiningParty(true);

    const partiesWithCode = await firestore
      .collection("parties")
      .orderBy("createdAt", "desc")
      .where("shortCode", "==", shortCode)
      .limit(1)
      .get();

    if (partiesWithCode.size > 0) {
      const party = partiesWithCode.docs[0];
      const partyId = party.id;

      const { default: avatar } = await import(
        `../assets/avatars/${Math.floor(Math.random() * 24) + 1}.png`
      );

      const result = await party.ref.collection("participants").add({
        avatar,
        name: name,
        createdAt: Timestamp.fromDate(new Date()),
        lastSeenAt: Timestamp.fromDate(new Date()),
      });

      currentParticipant.id = result.id;
      currentParticipant.partyId = partyId;

      setJoiningPartyId(partyId);
    } else {
      setJoiningParty(false);
      alert("Couldn't find party");
    }
  };

  const createParty = async () => {
    setCreatingParty(true);

    const result = await firestore.collection("parties").add({
      createdAt: Timestamp.fromDate(new Date()),
      shortCode: randomAlpha(4).toUpperCase(),
    } as IParty);

    setNewPartyId(result.id);
  };

  if (newPartyId) return <Redirect to={`/${newPartyId}/setup`} />;
  if (joiningPartyId) return <Redirect to={`/${joiningPartyId}/waiting`} />;

  return (
    <Container>
      <Box
        sx={{
          position: "absolute",
          left: 0,
          top: 0,
          zIndex: 2,
        }}
      >
        <Header />
      </Box>

      <Box className="show-on-mobile" p={4} bg="bg" height="100%" pt={5}>
        <PoolImage src={pool} />
        <form onSubmit={joinParty}>
          <Box flex="1" mt={4}>
            <TextInput
              mb={4}
              type="text"
              placeholder="Party code"
              value={shortCode}
              maxLength={4}
              onChange={(event) =>
                setShortCode(event.target.value.toUpperCase())
              }
              autoCapitalize="characters"
            />
            <TextInput
              mb={4}
              type="text"
              placeholder="Your name"
              value={name}
              maxLength={50}
              onChange={(event) => setName(event.target.value)}
              autoCapitalize="words"
            />
          </Box>
          <PrimaryButton
            width="100%"
            mb={4}
            type="submit"
            value="Join"
            disabled={joiningParty || creatingParty}
          >
            Jump in
          </PrimaryButton>
        </form>
        <Text color="white" className="show-on-mobile">
          Looking to host a party? Visit on your desktop.
        </Text>
      </Box>
      <Flex
        className="hide-on-mobile"
        alignItems="center"
        flexDirection="column"
      >
        <PoolImage src={pool} />
        <Button onClick={createParty} disabled={joiningParty || creatingParty}>
          Start Party
        </Button>

        <Text
          color="white"
          mt={5}
          fontWeight="700"
          bg="rgba(255,97,141,0.53)"
          sx={{
            borderRadius: "50px",
          }}
          p={3}
        >
          Looking to join a party? Visit on your phone.
        </Text>
      </Flex>
    </Container>
  );
}

export default Home;
