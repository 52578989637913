import React, { useContext, useEffect, useCallback } from "react";
import { useParams, Redirect } from "react-router-dom";
import { Image } from "rebass";
import { Flex, Box, Modal } from "../ui/layouts";
import {
  IGame,
  FirestoreContext,
  WithId,
  ISubmission,
  GameState,
} from "../firebase";
import { collection, collectionData } from "rxfire/firestore";
import { useObservable } from "rxjs-hooks";
import { useCurrentGame } from "../hooks/currentGame";
import { currentGameQuery } from "../hooks/currentGame";
import { Avatar, Text } from "../ui/atoms";
import { map, flatMap } from "rxjs/operators";
import background from "../assets/fullbackground-crazy8s.png";
import noIdeas from "../assets/crazy-eights-no-ideas.png";
import CrazyEightsLoader from "./CrazyEightsLoader";
import { usePresentParticipantsData } from "../hooks/participants";

function CrazyEightsHostPresentation() {
  const firestore = useContext(FirestoreContext);
  let { partyId } = useParams<{ partyId: string }>();
  const participants = usePresentParticipantsData(partyId);
  const game = useCurrentGame(partyId);

  const gameWithPresenterSubmissions = useObservable(() =>
    collection(currentGameQuery(firestore, partyId)).pipe(
      map((games) => games[0]),
      flatMap((game) => {
        const gameData = game.data();
        console.log(gameData);

        return collectionData<WithId<ISubmission>>(
          game.ref
            .collection("submissions")
            .where("participantId", "==", gameData.currentPresenter.id)
            .orderBy("createdAt", "asc"),
          "id"
        ).pipe(
          map((submissions) => {
            return { submissions, game };
          })
        );
      })
    )
  );

  const setCurrentPresenter = useCallback(
    async (id: string) => {
      if (!game) return;

      const submissionsQuery = await game.ref
        .collection("submissions")
        .where("participantId", "==", id)
        .orderBy("createdAt", "asc")
        .get();

      const submissions = submissionsQuery.docs;

      return await game.ref.set(
        {
          currentPresenter: {
            id,
            submissionId:
              submissions && submissions[0] ? submissions[0].id : "",
          },
        } as Partial<IGame>,
        { merge: true }
      );
    },
    [game]
  );

  const setCurrentSubmissionId = (id: string) => {
    if (!game) return;

    return game.ref.set(
      {
        currentPresenter: {
          submissionId: id,
        },
      } as Partial<IGame>,
      { merge: true }
    );
  };

  const endGame = () => {
    if (!game) return;

    return game.ref.set(
      {
        state: GameState.Complete,
      } as Partial<IGame>,
      { merge: true }
    );
  };

  useEffect(() => {
    if (game && participants) {
      const currentPresenterId = game.data().currentPresenter.id;
      const presentParticipantIds = participants.map((p) => p.id);
      const currentPresenterHasLeft = !presentParticipantIds.includes(
        currentPresenterId
      );

      if (currentPresenterHasLeft)
        setCurrentPresenter(presentParticipantIds[0]);
    }
  }, [participants, game, setCurrentPresenter]);

  if (!game) return <CrazyEightsLoader />;
  const gameData = game.data() as IGame;

  const currentSubmission =
    gameWithPresenterSubmissions &&
    gameWithPresenterSubmissions.submissions.find((submission) => {
      return gameData.currentPresenter.submissionId === submission.id;
    });

  if (gameData.state === GameState.Complete)
    return <Redirect to={`/${partyId}/setup`} />;

  return (
    <Flex
      p={5}
      height="100%"
      sx={{
        backgroundImage: `url(${background})`,
        background: "bg",
      }}
    >
      <Modal large>
        <Text
          onClick={endGame}
          fontWeight="bold"
          sx={{
            cursor: "pointer",
            textDecoration: "underline",
            position: "absolute",
            top: 16,
            right: 16,
          }}
        >
          End Game
        </Text>

        <Flex flexDirection="column" height="100%">
          <Flex justifyContent="center">
            {participants &&
              participants.map((participant) => (
                <Flex
                  onClick={() => setCurrentPresenter(participant.id)}
                  mx={2}
                  alignItems="center"
                  flexDirection="column"
                  key={participant.id}
                >
                  <Avatar
                    size={60}
                    mb={2}
                    key={participant.id}
                    src={participant.avatar}
                    sx={{
                      borderWidth: 3,
                      borderRadius: "50%",
                      borderStyle: "solid",
                      transition: "border 300ms ease-in-out",
                      borderColor:
                        gameData.currentPresenter.id === participant.id
                          ? "activeBorder"
                          : "border",
                    }}
                  />
                  <Text fontWeight="bold">{participant.name}</Text>
                </Flex>
              ))}
          </Flex>
          <Flex justifyContent="center" alignItems="center" flex={1}>
            {currentSubmission ? (
              <img
                alt="highlighted submission"
                style={{
                  background: "white",
                  height: "auto",
                  width: "auto",
                  border: "3px solid black",
                }}
                src={currentSubmission.imageData}
              />
            ) : (
              <Flex flexDirection="column" alignItems="center">
                <Image src={noIdeas} alt="no ideas" width="200px" mb={4} />
                <Text>No ideas :(</Text>
              </Flex>
            )}
          </Flex>
          <Flex justifyContent="center">
            {gameWithPresenterSubmissions &&
              gameWithPresenterSubmissions.submissions.map((submission) => (
                <Box
                  width="80px"
                  mx={1}
                  onClick={() => setCurrentSubmissionId(submission.id)}
                >
                  <img
                    alt="submission"
                    src={submission.imageData}
                    style={{
                      background: "white",
                      transition: "box-shadow 300ms ease-in-out",
                      boxShadow:
                        submission === currentSubmission
                          ? "0px 0px 0px 2px blue"
                          : "none",
                    }}
                  />
                </Box>
              ))}
          </Flex>
        </Flex>
      </Modal>
    </Flex>
  );
}

export default CrazyEightsHostPresentation;
