import React, { useContext } from "react";
import styled from "@emotion/styled";
import { currentGameQuery } from "../hooks/currentGame";
import { Redirect, useParams } from "react-router-dom";
import { Flex } from "../ui/layouts";
import CurrentParticipantContext from "../contexts/CurrentParticipantContext";
import { H1, Avatar } from "../ui/atoms";
import {
  IGame,
  FirestoreContext,
  WithId,
  ISubmission,
  GameState,
} from "../firebase";
import { useParticipantsData } from "../hooks/participants";
import { useObservable } from "rxjs-hooks";
import { collection, collectionData } from "rxfire/firestore";
import { map, flatMap } from "rxjs/operators";

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 16px;
`;

function CrazyEightsParticipantPresentation() {
  const firestore = useContext(FirestoreContext);

  const currentParticipant = useContext(CurrentParticipantContext);

  let { partyId } = useParams<{ partyId: string }>();
  const participants = useParticipantsData(partyId);

  const gameWithMySubmissions = useObservable(() =>
    collection(currentGameQuery(firestore, partyId))
      .pipe(map((games) => games[0]))
      .pipe(
        flatMap((game) => {
          return collectionData<WithId<ISubmission>>(
            game.ref
              .collection("submissions")
              .where("participantId", "==", currentParticipant.id)
              .orderBy("createdAt", "asc"),
            "id"
          ).pipe(
            map((submissions) => {
              return { submissions, game };
            })
          );
        })
      )
  );

  // TODO: this better belongs in a custom route
  if (currentParticipant.id === null) return <Redirect to="/" />;

  if (!gameWithMySubmissions || !participants)
    return <Flex height="100%" bg="bg" />;

  const { game, submissions } = gameWithMySubmissions;

  const gameData = game.data() as IGame;

  if (gameData.state === GameState.Complete)
    return <Redirect to={`/${partyId}/waiting`} />;

  const setCurrentSubmissionId = (id: string) => {
    return game.ref.set(
      {
        currentPresenter: {
          submissionId: id,
        },
      } as Partial<IGame>,
      { merge: true }
    );
  };

  if (gameData.currentPresenter.id === currentParticipant.id) {
    return (
      <Flex p={3} height="100%" bg="bg" flexDirection="column" overflow="auto">
        <H1 mb={3}>You are presenting</H1>
        <Grid>
          {submissions.map((submission) => (
            <img
              onClick={() => setCurrentSubmissionId(submission.id)}
              key={submission.id}
              alt="submission"
              style={{
                background: "white",
                boxShadow:
                  submission.id === gameData.currentPresenter.submissionId
                    ? "0px 0px 0px 3px blue"
                    : "none",
              }}
              src={submission.imageData}
            />
          ))}
        </Grid>
      </Flex>
    );
  } else {
    const currentPresenter = participants.find(
      (p) => p.id === gameData.currentPresenter.id
    );

    return (
      <Flex
        p={3}
        height="100%"
        bg="bg"
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
      >
        {currentPresenter && (
          <>
            <Avatar src={currentPresenter.avatar} mb={4} />
            <H1>{currentPresenter.name} is presenting</H1>
          </>
        )}
      </Flex>
    );
  }
}

export default CrazyEightsParticipantPresentation;
