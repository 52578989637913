import React from "react";
import styled from "@emotion/styled";
import { Link } from "react-router-dom";

interface IImageButton {
  image: string;
}

const Container = styled.div<IImageButton>`
  border-radius: 5px;
  padding: 16px;
  background: white;
  height: 150px;

  min-width: 250px;

  background-image: url(${p => p.image});
  background-size: cover;
`;

const ImageButton = ({ image, to, children }: any) => (
  <Link to={to} style={{ textDecoration: "none" }}>
    <Container image={image}>{children}</Container>
  </Link>
);
export default ImageButton;
