import { createContext } from "react";
import * as firebase from "firebase/app";
import "firebase/firestore";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
};

const firebaseApp = firebase.initializeApp(firebaseConfig);
const firestore = firebase.firestore(firebaseApp);

export const Timestamp = firebase.firestore.Timestamp;

const FirestoreContext = createContext(firestore);

export { firebaseApp, firestore, FirestoreContext };

export interface IParty {
  createdAt: firebase.firestore.Timestamp;
  shortCode: string;
}

// 15 Minutes for now -- might need to tighten this up for other
// types of games where realtime is more important.
export const PARTICIPANT_PRESENCE_MS = 900000;
export interface IParticipant {
  name: string;
  createdAt: firebase.firestore.Timestamp;
  avatar: string;
  lastSeenAt: firebase.firestore.Timestamp;
}

export enum GameType {
  CrazyEights = "CrazyEights",
}

export enum GameState {
  Ready = "Ready",
  Draw = "Draw",
  Presentation = "Presentation",
  Complete = "Complete",
}

export interface IPresenter {
  id: string;
  submissionId: string;
}

export interface IGame {
  type: GameType;
  prompt: string;
  state: GameState;
  createdAt: firebase.firestore.Timestamp;
  timerSeconds: number;
  currentPresenter: IPresenter;
}

export interface ISubmission {
  participantId: string;
  imageData: string;
  createdAt: firebase.firestore.Timestamp;
}

export type WithId<T> = T & { id: string };
