import TextInput from "./TextInput";
import styled from "@emotion/styled";

// might want to make this a variant of text input in the theme
const LargeInput = styled(TextInput)`
  width: 100%;
  font-size: 24px;
  padding: 16px;
  border-width: 2px;
  background: rgba(255, 255, 255, 0.15);
`;

export default LargeInput;
