import React, { useContext, useState } from "react";
import { useParams, Redirect } from "react-router-dom";
import { useObservable } from "rxjs-hooks";
import { doc } from "rxfire/firestore";
import { Flex, Modal } from "../ui/layouts";
import { H1, LargeInput, PrimaryButton } from "../ui/atoms";
import {
  FirestoreContext,
  GameType,
  IGame,
  GameState,
  Timestamp,
} from "../firebase";
import background from "../assets/fullbackground-crazy8s.png";
import CrazyEightsLoader from "./CrazyEightsLoader";

function CrazyEightsSetup() {
  const firestore = useContext(FirestoreContext);

  let { partyId } = useParams<{ partyId: string }>();
  const party = useObservable(() => doc(firestore.doc(`parties/${partyId}`)));

  const [prompt, setPrompt] = useState("");
  const [timer, setTimer] = useState((8 * 60) as number | string);
  const [showCustomTime, setShowCustomTime] = useState(false);
  const [customTime, setCustomTime] = useState(8 * 60);
  const [status, setStatus] = useState<null | "saving" | "saved">(null);

  const startGame = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (!party) return;
    setStatus("saving");

    await party.ref.collection("games").add({
      type: GameType.CrazyEights,
      prompt: prompt,
      state: GameState.Draw,
      timerSeconds: showCustomTime ? customTime : timer,
      createdAt: Timestamp.fromDate(new Date()),
    } as IGame);

    setStatus("saved");
  };

  if (!party) return <CrazyEightsLoader />;

  if (status === "saved")
    return <Redirect to={`/${partyId}/crazy-eights/host`} />;

  const handleTimerChange = (time: string | number) => {
    if (time === "custom") {
      return setShowCustomTime(true);
    }
    setShowCustomTime(false);

    setTimer(time);
  };

  return (
    <Flex
      p={4}
      height="100%"
      bg="bg"
      sx={{
        backgroundImage: `url(${background})`,
      }}
    >
      <Modal>
        <H1 mb={3}>What are you ideating?</H1>
        <form onSubmit={startGame}>
          <LargeInput
            placeholder="How can we encourage people to grow their own herbs?"
            as="textarea"
            mb={3}
            height="220px"
            type="text"
            onChange={(event) => setPrompt(event.target.value)}
          />
          <Flex>
            <LargeInput
              as="select"
              mb={4}
              sx={{ fontFamily: "monospace" }}
              onChange={(event) => handleTimerChange(event.target.value)}
            >
              <option value={1 * 60}>1 Minute</option>
              <option value={2 * 60}>2 Minutes</option>
              <option value={4 * 60}>4 Minutes</option>
              <option selected value={8 * 60}>
                8 Minutes
              </option>
              <option value={16 * 60}>16 Minutes</option>
              <option value={"custom"}>Custom Seconds</option>
            </LargeInput>
            {showCustomTime && (
              <LargeInput
                type="number"
                mb={4}
                ml={3}
                value={customTime}
                onChange={(e) => setCustomTime(parseInt(e.target.value))}
              />
            )}
          </Flex>

          <PrimaryButton
            type="submit"
            value="Start Game"
            disabled={status === "saving"}
          >
            Start Game
          </PrimaryButton>
        </form>
      </Modal>
    </Flex>
  );
}

export default CrazyEightsSetup;
