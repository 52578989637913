import { createContext } from "react";
import { observable } from "mobx";
import { persist } from "mobx-persist";

class CurrentParticipantStore {
  @persist @observable id: string | null = null;
  @persist @observable partyId: string | null = null;
}

const CurrentParticipantContext = createContext(new CurrentParticipantStore());

export { CurrentParticipantStore };
export default CurrentParticipantContext;
