import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { ThemeProvider } from "emotion-theming";
import { useLocalStore, observer } from "mobx-react";
import { create as createMobxPersist } from "mobx-persist";

import Home from "./screens/Home";
import PartySetup from "./screens/PartySetup";
import PartyWaitingRoom from "./screens/PartyWaitingRoom";
import CrazyEightsSetup from "./screens/CrazyEightsSetup";
import CrazyEightsParticipantGame from "./screens/CrazyEightsParticipantGame";
import CrazyEightsHostGame from "./screens/CrazyEightsHostGame";
import CrazyEightsParticipantPresentation from "./screens/CrazyEightsParticipantPresentation";
import CrazyEightsHostPresentation from "./screens/CrazyEightsHostPresentation";
import {
  FirestoreContext,
  firestore,
  IParticipant,
  Timestamp,
  PARTICIPANT_PRESENCE_MS,
} from "./firebase";
import CurrentParticipantContext, {
  CurrentParticipantStore,
} from "./contexts/CurrentParticipantContext";
import defaultTheme from "./ui/themes/defaultTheme";
import crazy8sTheme from "./ui/themes/crazy8sTheme";
import useAsyncEffect from "./hooks/useAsyncEffect";
import useInterval from "./hooks/useInterval";

const hydrate = createMobxPersist();

enum MobxHydrateState {
  None,
  Hydrating,
  Hydrated,
}

const App = observer(() => {
  const currentParticipant = useLocalStore(() => new CurrentParticipantStore());
  const [hydrateState, setHydrateState] = useState(MobxHydrateState.None);

  useAsyncEffect(async () => {
    if (hydrateState === MobxHydrateState.None) {
      setHydrateState(MobxHydrateState.Hydrating);
      await hydrate("currentParticipant", currentParticipant);
      setHydrateState(MobxHydrateState.Hydrated);
    }
  }, []);

  const presenceHeartbeat = () => {
    const { id, partyId } = currentParticipant;
    if (id === null && partyId === null) return;

    firestore.doc(`parties/${partyId}/participants/${id}`).set(
      {
        lastSeenAt: Timestamp.fromDate(new Date()),
      } as Partial<IParticipant>,
      {
        merge: true,
      }
    );
  };

  useEffect(presenceHeartbeat, [
    currentParticipant.id,
    currentParticipant.partyId,
  ]);
  useInterval(presenceHeartbeat, PARTICIPANT_PRESENCE_MS / 2);

  if (hydrateState === MobxHydrateState.Hydrated) {
    return (
      <ThemeProvider theme={defaultTheme}>
        <FirestoreContext.Provider value={firestore}>
          <CurrentParticipantContext.Provider value={currentParticipant}>
            <Router>
              <Switch>
                <Route path="/" exact>
                  <Home />
                </Route>
                <Route path="/:partyId/setup" exact>
                  <PartySetup />
                </Route>
                <Route path="/:partyId/waiting" exact>
                  <PartyWaitingRoom />
                </Route>
                <ThemeProvider theme={crazy8sTheme}>
                  <Route path="/:partyId/crazy-eights/setup" exact>
                    <CrazyEightsSetup />
                  </Route>
                  <Route path="/:partyId/crazy-eights" exact>
                    <CrazyEightsParticipantGame />
                  </Route>
                  <Route path="/:partyId/crazy-eights/host" exact>
                    <CrazyEightsHostGame />
                  </Route>
                  <Route path="/:partyId/crazy-eights/presentation" exact>
                    <CrazyEightsParticipantPresentation />
                  </Route>
                  <Route path="/:partyId/crazy-eights/host/presentation" exact>
                    <CrazyEightsHostPresentation />
                  </Route>
                </ThemeProvider>
              </Switch>
            </Router>
          </CurrentParticipantContext.Provider>
        </FirestoreContext.Provider>
      </ThemeProvider>
    );
  } else {
    return null;
  }
});

export default App;
