export function randomAlpha(length: number) {
  return randomString(length, ALPHA_CHARS);
}

export function randomString(length: number, availableChars: string[]) {
  let result = "";
  for (var i = length; i > 0; i--)
    result += availableChars[Math.floor(Math.random() * availableChars.length)];
  return result;
}

export const ALPHA_CHARS = [
  "a",
  "b",
  "c",
  "d",
  "e",
  "f",
  "g",
  "h",
  "i",
  "j",
  "k",
  "l",
  "m",
  "n",
  "o",
  "p",
  "q",
  "r",
  "s",
  "t",
  "u",
  "v",
  "w",
  "x",
  "y",
  "z",
];
