import React, { useContext, useState } from "react";
import { useParams, Redirect } from "react-router-dom";
import { Box, Flex } from "../ui/layouts";
import { PrimaryButton } from "../ui/atoms";
import { IGame, GameState, ISubmission, Timestamp } from "../firebase";
import Canvas from "../ui/organisms/Canvas";
import CurrentParticipantContext from "../contexts/CurrentParticipantContext";
import { useCurrentGame } from "../hooks/currentGame";

enum Status {
  Drawing,
  Submitting,
}

// think the right way to do this is with a ref but this works for the moment
const clearCanvas = () => {
  const canvas = document.querySelector("canvas");
  if (canvas) {
    const context = canvas.getContext("2d");
    context && context.clearRect(0, 0, canvas.width, canvas.height);
  }
};

function CrazyEightsParticipantGame() {
  const currentParticipant = useContext(CurrentParticipantContext);

  let { partyId } = useParams<{ partyId: string }>();
  const game = useCurrentGame(partyId);

  const [status, setStatus] = useState(Status.Drawing);
  const [imageData, setImageData] = useState("");

  const submitIdea = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (!imageData.length) return;
    if (!game) return;
    setStatus(Status.Submitting);

    await game.ref.collection("submissions").add({
      imageData,
      participantId: currentParticipant.id,
      createdAt: Timestamp.fromDate(new Date()),
    } as ISubmission);

    setImageData("");
    clearCanvas();
    setStatus(Status.Drawing);
  };

  // TODO: this better belongs in a custom route
  if (currentParticipant.id === null) return <Redirect to="/" />;

  if (!game) return <Flex bg="bg" height="100%" />;

  const gameData = game.data() as IGame;

  if (gameData.state === GameState.Presentation)
    return <Redirect to={`/${partyId}/crazy-eights/presentation`} />;

  return (
    <Flex flexDirection="column" bg="bg" height="100%">
      <Box flex={1}>
        <Canvas
          style={{ marginLeft: "auto", marginRight: "auto" }}
          width={window.innerWidth - 4}
          height={window.innerWidth - 4}
          onMouseUp={(imageData: string) => setImageData(imageData)}
        />
      </Box>
      <Box p={3}>
        <form onSubmit={submitIdea}>
          <PrimaryButton
            type="submit"
            disabled={status === Status.Submitting}
            sx={{ width: "100%" }}
          >
            Submit
          </PrimaryButton>
        </form>
      </Box>
    </Flex>
  );
}

export default CrazyEightsParticipantGame;
