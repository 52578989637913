import React from "react";
import Box from "./Box";

interface IModal {
  large?: boolean;
}

const Modal: React.FC<IModal> = (props: any) => (
  <Box
    p={[3, 4, 4]}
    bg="bg"
    sx={{
      margin: "auto",
      position: "relative",
      borderColor: "border",
      borderSize: 3,
      borderStyle: "solid",
      width: "100%",
      height: props.large ? "100%" : "unset",
      maxWidth: props.large ? "100%" : 800
    }}
    {...props}
  />
);

export default Modal;
