import React from "react";
import styled from "@emotion/styled";

const Logo = styled.div`
  font-size: 24px;
  font-family: rubik;
  font-weight: 600;
  -webit-text-stroke: 0.5px #f54a74;
  color: white;
`;

const Container = styled.header`
  padding: 24px;
`;

function Header() {
  return (
    <Container>
      <Logo>Pool Party</Logo>
    </Container>
  );
}

export default Header;
