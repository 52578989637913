export default {
  breakpoints: ["40em", "52em", "64em"],
  fontSizes: [12, 14, 16, 20, 24, 32, 48, 64],
  colors: {
    blue: "#07c",
    lightgray: "#f6f6ff",
    textLight: "white",
    subtle: "rgba(255,255,255,0.8)",
    bg: "#ff618d"
  },
  space: [0, 4, 8, 16, 32, 64, 128, 256],
  fonts: {
    body: "system-ui, sans-serif",
    heading: "Rubik",
    monospace: "Menlo, monospace"
  },
  fontWeights: {
    body: 400,
    heading: 700,
    bold: 700
  },
  lineHeights: {
    body: 1.5,
    heading: 1.25
  },
  shadows: {
    small: "0 0 4px rgba(0, 0, 0, .125)",
    large: "0 0 24px rgba(0, 0, 0, .125)"
  },
  variants: {},
  text: {
    heading: {
      color: "white"
    },
    default: {
      color: "white"
    },
    big: {
      color: "white",
      fontSize: 20
    }
  },
  forms: {
    input: {
      borderColor: "white",
      background: "rgba(255,255,255,0.2)",
      padding: 16
    }
  },
  buttons: {
    primary: {
      fontSize: 24,
      borderRadius: 5,
      borderWidth: "1px",
      borderColor: "white",
      fontWeight: "bold",
      fontFamily: "rubik",
      color: "#474747",
      bg: "white"
    }
  }
};
