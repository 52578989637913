import React from "react";
import { Flex, Modal } from "../ui/layouts";
import background from "../assets/fullbackground-crazy8s.png";

function CrazyEightsLoader() {
  return (
    <Flex
      p={4}
      height="100%"
      sx={{
        backgroundImage: `url(${background})`
      }}
    >
      <Modal>Loading</Modal>
    </Flex>
  );
}

export default CrazyEightsLoader;
